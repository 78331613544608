<template>
    <div class="profile container">
        <div>
            <h3 class="ui header">
                Edit My Profile
                <div class="sub header">Make profile edit and changes here</div>
            </h3>
        </div>
        <div class="ui hidden divider"></div>
        <div class="ui basic container">
            <div class="ui stackable profile grid">
                <div class="five wide center aligned column">
                    <div class="avatar-wrapper">
                        <avatar initial-class="circular purple" :user="profile"/>
                        <button class="ui icon circular teal small button" type="button" @click="createAvataaars"><i class="pencil alternate icon"></i></button>
                    </div>
                </div>
                <div class="eleven wide column">
                    <h4 class="ui header">Personal Information</h4>
                    <form class="ui form" :class="{loading: submitting}" @submit.prevent="updateProfile">
                        <div class="required field">
                            <label>Name</label>
                            <div class="two fields">
                                <div class="field">
                                    <input type="text" placeholder="First name" v-model.trim="profile.first_name" required/>
                                </div>
                                <div class="field">
                                    <input type="text" placeholder="Last name" v-model.trim="profile.last_name" required/>
                                </div>
                            </div>
                        </div>

                        <div class="two required fields">
                            <div class="field">
                                <label>Gender</label>
                                <select ref="genderdropdown" class="ui selection dropdown" v-model="profile.gender" required>
                                    <option value=""></option>
                                    <option :value="$gender.FEMALE">{{$t("common.female")}}</option>
                                    <option :value="$gender.MALE">{{$t("common.male")}}</option>
                                </select>
                            </div>
                        </div>

                        <div class="two fields">
                            <div class="required field">
                                <label>Email</label>
                                <input type="email" pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,63}$" v-model.trim="profile.username" v-lowercase required/>
                            </div>
                            <div class="field" :class="{required: isAdmin}">
                                <label>Mobile Number</label>
                                <vue-phone-number-input v-model="mobile_no" :required="isAdmin" clearable no-example color="#85b7d9" default-country-code="MY" :translations="{
                                    countrySelectorLabel: '',
                                    countrySelectorError: '',
                                    phoneNumberLabel: '',
                                    example: ''
                                }" @update="mobileNoChanged"/>
                            </div>
                        </div>

                        <div class="two fields">
                            <div class="field">
                                <label>Outlet</label>
                                <div ref="outletdropdown" class="ui clearable selection dropdown">
                                    <input type="hidden" :value="profile.outlet" @change="changeOutlet($event.target.value)"/>
                                    <i class="dropdown icon"></i>
                                    <div class="default text">&nbsp;</div>
                                    <div class="menu">
                                        <div class="item" v-for="outlet in outlets" :key="`outlet_option_${outlet.id}`" :data-value="outlet.id">
                                            {{outlet.name}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="ui hidden divider"></div>

                        <div class="ui right aligned text">
                            <button type="submit" class="ui submit teal button">Update</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>

        <div ref="avataaarsmodal" class="ui modal">
            <div class="content">
                <h2 class="ui header">Personalize Avatar</h2>
                <div class="ui hidden divider"></div>
                <div class="ui stackable grid">
                    <div class="five wide column">
                        <avataaars v-bind="avataaars"/>
                    </div>
                    <div class="eleven wide column">
                        <form :id="`avataaarsform${_uid}`" class="ui form" @submit.prevent="saveAvataaar">
                            <div class="two fields">
                                <div class="field">
                                    <label>Background Colour</label>
                                    <input type="color" v-model="avataaars.circleColor"/>
                                </div>
                                <div class="field">
                                    <label>Accessories</label>
                                    <select class="ui selection dropdown" v-model="avataaars.accessoriesType">
                                        <option value="Blank">None</option>
                                        <option value="Kurt">Kurt</option>
                                        <option value="Prescription01">White Frame</option>
                                        <option value="Prescription02">Black Frame</option>
                                        <option value="Round">Round</option>
                                        <option value="Sunglasses">Sunglasses</option>
                                        <option value="Wayfarers">Wayfarers</option>
                                    </select>
                                </div>
                            </div>
                            <div class="two fields">
                                <div class="field">
                                    <label>Clothes</label>
                                    <select class="ui selection dropdown" v-model="avataaars.clotheType">
                                        <option value="BlazerShirt">Blazer</option>
                                        <option value="BlazerSweater">Blazer + Sweater</option>
                                        <option value="CollarSweater">Collar Sweater</option>
                                        <option value="GraphicShirt">Graphic T-shirt</option>
                                        <option value="Hoodie">Hoodie</option>
                                        <option value="Overall">Overall</option>
                                        <option value="ShirtCrewNeck">Crew Neck T-shirt</option>
                                        <option value="ShirtScoopNeck">Scoop Neck T-shirt</option>
                                        <option value="ShirtVNeck">V Neck T-shirt</option>
                                    </select>
                                </div>
                                <div class="field">
                                    <label>Clothes Colour</label>
                                    <select class="ui selection dropdown" v-model="avataaars.clotheColor">
                                        <option value="Black">Black</option>
                                        <option value="Blue01">Blue #1</option>
                                        <option value="Blue02">Blue #2</option>
                                        <option value="Blue03">Blue #3</option>
                                        <option value="Gray01">Gray #1</option>
                                        <option value="Gray02">Gray #2</option>
                                        <option value="Heather">Heather</option>
                                        <option value="PastelBlue">Pastel Blue</option>
                                        <option value="PastelGreen">Pastel Green</option>
                                        <option value="PastelOrange">Pastel Orange</option>
                                        <option value="PastelRed">Pastel Red</option>
                                        <option value="PastelYellow">Pastel Yellow</option>
                                        <option value="Pink">Pink</option>
                                        <option value="Red">Red</option>
                                        <option value="White">White</option>
                                    </select>
                                </div>
                            </div>
                            <div class="two fields">
                                <div class="field">
                                    <label>Eye</label>
                                    <select class="ui selection dropdown" v-model="avataaars.eyeType">
                                        <option value="Close">Close</option>
                                        <option value="Cry">Cry</option>
                                        <option value="Default">Default</option>
                                        <option value="Dizzy">Dizzy</option>
                                        <option value="EyeRoll">Rolling</option>
                                        <option value="Happy">Happy</option>
                                        <option value="Hearts">Love</option>
                                        <option value="Side">Side</option>
                                        <option value="Squint">Squint</option>
                                        <option value="Surprised">Surprised</option>
                                        <option value="Wink">Wink</option>
                                        <option value="WinkWacky">WinkWacky</option>
                                    </select>
                                </div>
                                <div class="field">
                                    <label>Eyebrow</label>
                                    <select class="ui selection dropdown" v-model="avataaars.eyebrowType">
                                        <option value="Angry">Angry</option>
                                        <option value="AngryNatural">Natural Angry</option>
                                        <option value="Default">Default</option>
                                        <option value="DefaultNatural">Natural Default</option>
                                        <option value="FlatNatural">Flat</option>
                                        <option value="RaisedExcited">Raised/Excited</option>
                                        <option value="RaisedExcitedNatural">Natural Raised/Excited</option>
                                        <option value="SadConcerned">Sad/Concerned</option>
                                        <option value="SadConcernedNatural">Natural Sad/Concerned</option>
                                        <option value="UnibrowNatural">Natural Unibrow</option>
                                        <option value="UpDown">Up Down</option>
                                        <option value="UpDownNatural">Natural Up Down</option>
                                    </select>
                                </div>
                            </div>
                            <div class="two fields">
                                <div class="field">
                                    <label>Mouth</label>
                                    <select class="ui selection dropdown" v-model="avataaars.mouthType">
                                        <option value="Concerned">Concerned</option>
                                        <option value="Default">Default</option>
                                        <option value="Disbelief">Disbelief</option>
                                        <option value="Eating">Eating</option>
                                        <option value="Grimace">Grimace</option>
                                        <option value="Sad">Sad</option>
                                        <option value="ScreamOpen">Open Scream</option>
                                        <option value="Serious">Serious</option>
                                        <option value="Smile">Smile</option>
                                        <option value="Tongue">Tongue</option>
                                        <option value="Twinkle">Twinkle</option>
                                        <option value="Vomit">Vomit</option>
                                    </select>
                                </div>
                                <div class="field">
                                    <label>Skin Colour</label>
                                    <select class="ui selection dropdown" v-model="avataaars.skinColor">
                                        <option value="Tanned">Tanned</option>
                                        <option value="Yellow">Yellow</option>
                                        <option value="Pale">Pale</option>
                                        <option value="Light">Light</option>
                                        <option value="Brown">Brown</option>
                                        <option value="DarkBrown">Dark Brown</option>
                                        <option value="Black">Black</option>
                                    </select>
                                </div>
                            </div>
                            <div class="two fields">
                                <div class="field">
                                    <label>Top</label>
                                    <select class="ui selection dropdown" v-model="avataaars.topType">
                                        <option value="NoHair">No Hair</option>
                                        <option value="Eyepatch">Eyepatch</option>
                                        <option value="Hat">Hat</option>
                                        <option value="Hijab">Hijab</option>
                                        <option value="Turban">Turban</option>
                                        <option value="WinterHat1">Winter Hat #1</option>
                                        <option value="WinterHat2">Winter Hat #2</option>
                                        <option value="WinterHat3">Winter Hat #3</option>
                                        <option value="WinterHat4">Winter Hat #4</option>
                                        <option value="LongHairBigHair">Long Big Hair</option>
                                        <option value="LongHairBob">Long Bob Hair</option>
                                        <option value="LongHairBun">Long Hair Bun</option>
                                        <option value="LongHairCurly">Long Curly Hair</option>
                                        <option value="LongHairCurvy">Long Curvy Hair</option>
                                        <option value="LongHairDreads">Long Dreaded Hair</option>
                                        <option value="LongHairFrida">Long Frida Hair</option>
                                        <option value="LongHairFro">Long Fro Hair</option>
                                        <option value="LongHairFroBand">Long Fror Hair + Band</option>
                                        <option value="LongHairNotTooLong">Not Too Long Hair</option>
                                        <option value="LongHairShavedSides">Long Hair + Shaved Sides</option>
                                        <option value="LongHairMiaWallace">Long Mia Wallace Hair</option>
                                        <option value="LongHairStraight">Long Straight Hair</option>
                                        <option value="LongHairStraight2">Long Straight Hair #2</option>
                                        <option value="LongHairStraightStrand">Long Straigh Strand Hair</option>
                                        <option value="ShortHairDreads01">Short Dreaded Hair #1</option>
                                        <option value="ShortHairDreads02">Short Dreaded Hair #2</option>
                                        <option value="ShortHairFrizzle">Short Frizzle Hair</option>
                                        <option value="ShortHairShaggyMullet">Short Shaggy Mullet Hair</option>
                                        <option value="ShortHairShortCurly">Short Curly Hair</option>
                                        <option value="ShortHairShortFlat">Short Flat Hair</option>
                                        <option value="ShortHairShortRound">Short Round Hair</option>
                                        <option value="ShortHairShortWaved">Short Wave Hair</option>
                                        <option value="ShortHairSides">Short Sides Hair</option>
                                        <option value="ShortHairTheCaesar">Short The Caeser Hair</option>
                                        <option value="ShortHairTheCaesarSidePart">Short The Caeser Side Part Hair</option>
                                    </select>
                                </div>
                                <div class="field">
                                    <label>Top Colour</label>
                                    <select class="ui selection dropdown" v-model="avataaars.topColor">
                                        <option value="Black">Black</option>
                                        <option value="Blue01">Blue #1</option>
                                        <option value="Blue02">Blue #2</option>
                                        <option value="Blue03">Blue #3</option>
                                        <option value="Gray01">Gray #1</option>
                                        <option value="Gray02">Gray #2</option>
                                        <option value="Heather">Heather</option>
                                        <option value="PastelBlue">Pastel Blue</option>
                                        <option value="PastelGreen">Pastel Green</option>
                                        <option value="PastelOrange">Pastel Orange</option>
                                        <option value="PastelRed">Pastel Red</option>
                                        <option value="PastelYellow">Pastel Yellow</option>
                                        <option value="Pink">Pink</option>
                                        <option value="Red">Red</option>
                                        <option value="White">White</option>
                                    </select>
                                </div>
                            </div>
                            <div class="two fields">
                                <div class="field">
                                    <label>Hair Colour</label>
                                    <select class="ui selection dropdown" v-model="avataaars.hairColor">
                                        <option value="Auburn">Auburn</option>
                                        <option value="Black">Black</option>
                                        <option value="Blonde">Blonde</option>
                                        <option value="BlondeGolden">Golden Blonde</option>
                                        <option value="Brown">Brown</option>
                                        <option value="BrownDark">Dark Brown</option>
                                        <option value="PastelPink">Pastel Pink</option>
                                        <option value="Platinum">Platinum</option>
                                        <option value="Red">Red</option>
                                        <option value="SilverGray">Silver Gray</option>
                                    </select>
                                </div>
                                <div class="field">
                                    <label>Graphic</label>
                                    <select class="ui selection dropdown" v-model="avataaars.graphicType">
                                        <option value="Bat">Bat</option>
                                        <option value="Cumbia">Cumbia</option>
                                        <option value="Deer">Deer</option>
                                        <option value="Diamond">Diamond</option>
                                        <option value="Hola">Hola</option>
                                        <option value="Pizza">Pizza</option>
                                        <option value="Resist">Resist</option>
                                        <option value="Selena">Selena</option>
                                        <option value="Bear">Bear</option>
                                        <option value="SkullOutline">Skull Outline</option>
                                        <option value="Skull">Skull</option>
                                    </select>
                                </div>
                            </div>
                            <div class="two fields">
                                <div class="field">
                                    <label>Facial Hair</label>
                                    <select class="ui selection dropdown" v-model="avataaars.facialHairType">
                                        <option value="Blank">None</option>
                                        <option value="BeardMedium">Medium Beard</option>
                                        <option value="BeardLight">Light Beard</option>
                                        <option value="BeardMagestic">Magestic Beard</option>
                                        <option value="MoustacheFancy">Fancy Moustache</option>
                                        <option value="MoustacheMagnum">Magnum Moustache</option>
                                    </select>
                                </div>
                                <div class="field">
                                    <label>Facial Hair Colour</label>
                                    <select class="ui selection dropdown" v-model="avataaars.facialHairColor">
                                        <option value="Auburn">Auburn</option>
                                        <option value="Black">Black</option>
                                        <option value="Blonde">Blonde</option>
                                        <option value="BlondeGolden">Golden Blonde</option>
                                        <option value="Brown">Brown</option>
                                        <option value="BrownDark">Dark Brown</option>
                                        <option value="PastelPink">Pastel Pink</option>
                                        <option value="Platinum">Platinum</option>
                                        <option value="Red">Red</option>
                                        <option value="SilverGray">Silver Gray</option>
                                    </select>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div class="actions">
                <button type="submit" :form="`avataaarsform${_uid}`" class="ui submit teal button">OK</button>
                <button type="button" class="ui alt red cancel button">Cancel</button>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from "vuex";

import Avatar from "@/components/Avatar";
import Avataaars from "vuejs-avataaars";
import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";

export default {
    name: "profile",
    components: {
        Avatar,
        Avataaars,
        VuePhoneNumberInput
    },
    data() {
        return {
            profile: {
                username: "",
                first_name: "",
                last_name: "",
                gender: "",
                mobile_no: null,
                outlet: null,
                avataaars: null
            },
            mobile_no: "",
            avataaars: {},
            outlets: [],
            submitting: false,
            opened_modals: [],
            history_popped: false
        };
    },
    created() {
        this.profile.username = this._user?.username || "";
        this.profile.first_name = this._user?.first_name || "";
        this.profile.last_name = this._user?.last_name || "";
        this.profile.gender = this._user?.gender || "";
        this.profile.mobile_no = this._user?.mobile_no || null;
        this.profile.outlet = this._user?.outlet || null;
        this.profile.avataaars = this._user?.avataaars || null;

        if(this.profile.mobile_no) {
            this.mobile_no = "+" + this.profile.mobile_no;
        }
    },
    mounted() {
        window.addEventListener("popstate", this.popstateHandler);

        this.$http.get("outlets").then(({ data }) => {
            this.outlets = data;
        }).catch((error) => {
            console.error(error);

            this.$toasted.error("Unable to list outlets.", {
                duration: 3000
            });
        }).finally(() => {
            $(this.$refs.outletdropdown).dropdown();
        });

        this.$nextTick(() => {
            $(this.$refs.genderdropdown).dropdown();

            $(this.$refs.avataaarsmodal).modal({
                closable: false,
                onVisible: () => {
                    history.pushState(null, "avataaarsmodal_opened");
                    this.opened_modals.push("avataaarsmodal");
                },
                onHide: () => {
                    //maybe use onHidden?
                    if(!this.history_popped) {
                        history.back();
                    }

                    this.opened_modals = this.opened_modals.filter((modal) => {
                        return modal !== "avataaarsmodal";
                    });
                }
            });
        });
    },
    beforeDestroy() {
        window.removeEventListener("popstate", this.popstateHandler);
    },
    methods: {
        popstateHandler(event) {
            this.history_popped = true;
            this.opened_modals.forEach((modal) => {
                $(this.$refs[modal]).modal("hide");
            });

            this.history_popped = false;
        },
        createAvataaars() {
            this.avataaars = this.profile.avataaars || {
                isCircle: true,
                circleColor: "#6fb8e0",
                accessoriesType: "Blank",
                clotheType: "GraphicShirt",
                clotheColor: "Pink",
                eyeType: "Default",
                eyebrowType: "Default",
                mouthType: "Default",
                skinColor: "Light",
                topType: "LongHairMiaWallace",
                topColor: "Red",
                hairColor: "SilverGray",
                graphicType: "Diamond",
                facialHairType: "BeardLight",
                facialHairColor: "BrownDark"
            };

            this.$nextTick(() => {
                $(this.$refs.avataaarsmodal).find(".ui.selection.dropdown").dropdown();
                $(this.$refs.avataaarsmodal).modal("show");
            });
        },
        saveAvataaar() {
            this.profile.avataaars = this.avataaars;
            $(this.$refs.avataaarsmodal).modal("hide");
        },
        changeOutlet(outlet) {
            if(outlet) {
                this.$set(this.profile, "outlet", +outlet);
            } else {
                this.$set(this.profile, "outlet", null);
            }
        },
        updateProfile() {
            if(this.isAdmin && !this.profile.mobile_no) {
                this.$toasted.error("Please provide a valid mobile number.", {
                    duration: 3000
                });

                return;
            }

            this.submitting = true;

            if(!this.profile.mobile_no) {
                this.profile.mobile_no = null;
            }

            this.$http.put(`users/${this._user.id}`, this.profile).then(({data}) => {
                let _user = Object.assign({}, this._user, data);

                this.updateUser(_user).finally(() => {
                    this.submitting = false;

                    this.$toasted.success("Consultation saved successfully.", {
                        duration: 3000,
                        action: {
                            text: "OK",
                            onClick: (e, toast) => {
                                toast.goAway(0);
                            }
                        }
                    });
                });
            }).catch((error) => {
                console.error(error);

                const status_code = error.response?.status || "";
                const message = (status_code === 409)? "Email (username) already exists." : "Failed to update profile.";

                let action = [];
                if(status_code !== 409) {
                    action.push({
                        text: "REPORT ERROR",
                        href: `mailto:tech@1991skincare.com?subject=Error%20updating%20profile&body=${encodeURIComponent(JSON.stringify(this.profile))}`
                    });
                }

                action.push({
                    text: (status_code === 409)? "OK" : "CLOSE",
                    onClick: (e, toast) => {
                        this.submitting = false;
                        toast.goAway(0);
                    }
                });

                this.$toasted.error(message, {
                    action,
                    onComplete: () => {
                        this.submitting = false;
                    }
                });
            });
        },
        mobileNoChanged(data) {
            if(data.isValid) {
                this.profile.mobile_no = data.countryCallingCode + data.nationalNumber;
            } else {
                this.profile.mobile_no = null;
            }
        },
        ...mapActions({
            updateUser: "auth/updateUser"
        })
    }
};
</script>

<style lang="scss" scoped>
.ui.profile.grid {
    &.container {
        margin-left: 0 !important;
    }

    .avatar-wrapper {
        max-width: 256px;
        position: relative;
        margin: auto;

        ::v-deep {
            .avatar {
                width: 100%;
                margin: auto;
            }
        }

        .ui.button {
            position: absolute;
            bottom: 0;
            right: 0;
        }
    }

    .ui.form {
        animation: animation-slideUp ease-out 300ms;
    }

    ::v-deep {
        .country-selector .country-selector__input {
            border-radius: 0;
            border-right: none;
        }

        .country-selector__country-flag,
        .country-selector__toggle,
        .input-tel__clear {
            top: 50%;
            transform: translate(0, -50%);
        }

        .country-selector .country-selector__input,
        .input-tel .input-tel__input {
            padding-top: .67857143em;
            height: auto;
            min-height: 0;
        }

        .input-tel__clear > span:not(.input-tel__clear__effect) {
            top: 0;
        }
    }
}

input[type="color"] {
    width: 100%;
    min-height: 2.71428571em;
    border: 1px solid rgba(34,36,38,.15);
    border-radius: .28571429rem;
}
</style>